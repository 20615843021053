export const track = args => {
  if (window && window.dataLayer) {
    if (args.constructor.name === 'Object') {
      window.dataLayer.push(args)
    }
  }
}

export const getSearchTermFromReferrer = referrer => {
  let searchEngine, searchTerm
  if (referrer) {
    const referrerURL = new URL(referrer)
    if (referrerURL) {
      if (referrerURL.hostname.includes('google')) {
        searchEngine = 'Google'
        searchTerm = referrerURL.searchParams.get('q')
      } else if (referrerURL.hostname.includes('bing')) {
        searchEngine = 'Bing'
        searchTerm = referrerURL.searchParams.get('q')
      } else if (referrerURL.hostname.includes('yahoo')) {
        searchEngine = 'Yahoo'
        searchTerm = referrerURL.searchParams.get('p')
      }
    }
  }

  return { searchEngine, searchTerm }
}
