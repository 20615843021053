import React from 'react'
import './styles.scss'

function BackgroundImage(props) {
  return (
    <div
      className="BackgroundImage"
      style={{
        '--image': `url(${props.image})`,
        '--opacity': props.opacity,
        height: props.height ? props.height : '',
      }}
    />
  )
}

export default BackgroundImage
